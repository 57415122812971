import React, { useEffect, useRef } from 'react';
import './Navbar.css'; // Importing custom CSS for complex styles

function Navbar() {
    const navbarRef = useRef(null);

    useEffect(() => {
        if (navbarRef.current) {
            navbarRef.current.style.opacity = 0;
            setTimeout(() => {
                navbarRef.current.style.opacity = 1;
                navbarRef.current.style.transform = 'translateY(0)';
            }, 100); // Delay to allow CSS transition
        }
    }, []);

    return (
      <nav className="navbar" ref={navbarRef} style={{ transform: 'translateY(-20px)', transition: 'opacity 0.5s ease, transform 0.5s ease' }}>
        <a href="/" className="logo">SHKC.PRO</a>
        <div className="nav-items">
          <a href="#home">HOME</a>
          <a href="#aboutme">ABOUT</a>
          <a href="#projects">PROJECTS</a>
          <a href="#contact">CONTACT</a>
        </div>
      </nav>
    );
  }

export default Navbar;