import React from 'react';
import Navbar from './Navbar'; // Import the Navbar component
import Hero from './Hero'; // Import the Hero component
import Projects from './Projects'; // Import the Projects component
import Aboutme from './Aboutme'; // Import the Aboutme component
import Contact from './Contact'; // Import the Contact component
import './App.css';

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Projects />
      <Aboutme />
      <Contact />
    </div>
  );
}

export default App;