import React, { useEffect } from 'react';
import './Hero.css'; // Importing custom CSS for complex styles

function Hero() {
  useEffect(() => {
    const heroSection = document.querySelector('.hero');
    heroSection.style.opacity = 1;
    heroSection.style.transform = 'translateY(0)';
  }, []);

  return (
    <section className="hero">
      <div id="home" className="hero-content">
        <h1>Full Stack Developer</h1>
        <p>A developer with a dream of launching a sucessful startup.</p>
        <a href="#contact" className="hero-button">Contact</a>
      </div>
    </section>
  );
}

export default Hero;
